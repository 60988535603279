import img from '../../image/ashawin.jpg'
export const data=[
    {
        id:1,
        name:'Destination Wedding In Rajasthan With 2 Wedding Ceremonies & A Regal TouchBy Sanjay',
        into:"Now here was a South Indian wedding that was different! Anuroopa and Vishal planned their destination wedding in Rajasthan, and had not one, but 2 beautiful wedding ceremonies at the palatial venue. The decor was elegant and tasteful, and the bride made quite the entry at both the weddings- now isn't that amazing? Saaj Weddings★ 4.9 planned this Jodhpur wedding beautifully, and brought the couple's vision to life. ",
        date:'26 Jul, 2024',
        views:'887 views',
        read:'2 Min',
        author:'Sanjay',
        image:img,
        p:[
            {
                pid:1,
                content:'When Anuroopa and Vishal decided to tie the knot, they knew they wanted their wedding to be special—something that would reflect their personalities, traditions, and their love for each other. So, they chose Rajasthan, a land of royalty and grandeur, as the destination for their dream wedding. But they didn’t stop at just one ceremony. Instead, they celebrated their love with not one, but two beautiful weddings, making their special day even more unforgettable.'
            },{
                pid:2,
                content:'The Venue: A Royal Affair in Jodhpur: For their big day, Anuroopa and Vishal picked Jodhpur, the Blue City of Rajasthan, known for its majestic palaces and rich history. The wedding was set in a grand palace that felt like it was right out of a fairy tale. With its stunning architecture and regal charm, the venue provided a perfect backdrop for a wedding that was both luxurious and intimate.'
            },
            {
                pid:3,
                content:'Two Ceremonies, Double the Celebration: Anuroopa and Vishal’s wedding was unique because they chose to have two different ceremonies, each with its own style and significance.'
            },
            {
                pid:4,
                content:'The first ceremony was a traditional South Indian wedding. This ceremony was all about honoring their roots and traditions. The decor was vibrant, featuring marigold flowers and classic temple designs that added a touch of heritage to the event. Anuroopa looked stunning in her traditional Kanjeevaram saree as she made her entrance, leaving everyone in awe.'
            },
            {
                pid:5,
                content:'The second ceremony was a more modern celebration. The decor was chic and elegant, with soft lighting, delicate floral arrangements, and a stylish setup that gave the event a contemporary vibe. For this ceremony, Anuroopa wore a fusion outfit that perfectly blended traditional and modern elements, reflecting the spirit of the day. Her entrance was nothing short of spectacular, making it a moment to remember.'
            },
            {
                pid:6,
                content:'Planning two distinct ceremonies in a destination as grand as Jodhpur requires a lot of skill and attention to detail. That’s where Saaj Weddings came in. Known for their expertise in creating luxurious and personalized weddings, they made sure that every aspect of the wedding was executed flawlessly. From the grand entrance of the bride to the intimate details of the decor, Saaj Weddings turned Anuroopa and Vishal’s dream wedding into reality. Their hard work and dedication were evident in every moment of the celebration.',
                head:'The Dream Team: Saaj Weddings'
            },
            {
                pid:7,
                content:'A Royal Experience for Everyone: What made this wedding truly special was the royal experience that Anuroopa and Vishal created for their guests. From the moment they arrived, the guests were treated to the finest hospitality, making them feel like royalty themselves. Saaj Weddings ensured that every detail was taken care of, from the grand procession of the baraat to the luxurious seating arrangements. It was a wedding that left a lasting impression on everyone who atteA Wedding to Remember'
            },
            {
                pid:8,
                content:'Anuroopa and Vishal’s wedding was more than just a union of two people; it was a celebration of love, tradition, and the beginning of a new journey together. By choosing to have two ceremonies, they were able to honor their cultural heritage while also embracing a modern outlook. Their wedding was a perfect blend of the old and the new, creating a day that was as meaningful as it was beautiful.'
            },
            {
                pid:9,
                head:' Conclusion'
            },
            {
                pid:10,
                content:'Anuroopa and Vishal’s destination wedding in Rajasthan was a true reflection of their love and their desire to create something special. With the help of Saaj Weddings, they were able to bring their vision to life in a way that was both luxurious and deeply personal. Their story is a reminder that with the right planning and a little bit of royal magic, any wedding can be transformed into an unforgettable celebration.'
            },
            {
                pid:11,
                content:'For anyone dreaming of a wedding that combines tradition with modern elegance, Anuroopa and Vishal’s wedding in Jodhpur is proof that anything is possible. It’s a celebration that will be remembered for years to come, not just by the couple, but by everyone who was lucky enough to be a part of it.'
            }
         ]
    }
]