import React from 'react'
import {data} from '../Constant/blogs.js'
export default function Blogs() {
  return (
    <div className='w-[100vw] flex justify-center items-center overflow-hidden'>
        <div className='w-[100%] max-w-[1350px] px-[5%] py-[2rem] '>
            <div className='lg:flex lg:flex-wrap max-lg:flex max-lg:flex-col gap-10 w-[100%] justify-center items-center  '>
               {data && data.map((data,index)=>
              <div className='lg:w-[35%] bg-slate-50 max-lg:w-[100%] flex flex-col gap-3 shadow-lg relative'>
                    <div className='h-[20rem] w-[100%]  relative'>
                        <img src={data?.image} className='h-[100%] w-[100%] object-cover' alt="" />
                        <div className="bg-[#4e4e4d] absolute bottom-[-1rem] left-[1rem]  rounded-full  inline-block w-fit px-3 text-white  font-[600] py-1">
                            <h1 className='font-bold capitalize'>By {data?.author}
                                </h1></div>
                                <div className="bg-[#e6ba44] absolute top-[-1rem] left-[1rem]  rounded-full  inline-block w-fit px-3 text-white  font-[600] py-1">
                            <h1 className='font-bold capitalize' >{data.views} 
                                </h1></div>
                                <div className="bg-[#e6ba44] absolute top-[-1rem] right-[1rem]  rounded-full  inline-block w-fit px-3 text-white  font-[600] py-1">
                            <h1 className='font-bold capitalize'>{data.read} 
                                </h1></div>
                    </div>
                    <div className='flex flex-col gap-4 p-3 text-left'>
                        <h className='text-xl  font-semibold text-[#e6d070] tracking-wider'>
                            {data.name}
                        </h>
                        <p className="text-md  font-noraml tracking-wider">
                           {data.into.slice(0,150)}...
                        </p>
                        <button className="bg-[#e6d070] rounded-full  inline-block w-fit px-3 text-white  font-bold py-1">
                          <a href={`blog/${data.id}`}>Know More</a>  
                        </button>
                        <div className='flex gap-5 mt-6 w-[100%] border-t-2 pt-2 items-center'>
                            <div className="w-[3rem] h-[3rem] rounded-full bg-[#252525] flex justify-center items-center text-[#ccc3c3] text-md">
                                A
                            </div>
                            <div >
                                <h1 className='font-semibold tracking-wider capitalize '>Saaj Weddings</h1>
                                <span className="text-sm ">{data.date}</span>
                            </div>

                        </div>
                    </div>

                </div>)}

            </div>

        </div>
      
    </div>
  )
}
