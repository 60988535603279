import './App.css';



// import Client from "./components/Client/Client";

// import Cover from "./components/Cover";

import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Services from './components/Services/Services';
// import Home from './components/Home';
import NewHome from './components/NewHome.jsx'


// import About from './components/About/About';

// import Gallarys from './components/Gallary/Gallary';
import Blog from './components/BLog/Blog.jsx';
// import Demos from './Admin/Demo.jsx'
import Admin from './Admin/Admin.jsx';
import Navbars from './components/Navbar.jsx';
// import Contact from './components/Contact/Contact.jsx';

import { lazy, Suspense } from 'react';
import { FaSpinner } from 'react-icons/fa';
import BlogDetails from './components/BLog/BlogDetails.jsx';
const Cover= lazy(()=>import("./components/Cover"))
const Client=lazy(()=>import("./components/Client/Client"))
const About=lazy(()=>import('./components/About/About'))
const Contact=lazy(()=>import('./components/Contact/Contact.jsx'))
const Services=lazy(()=>import('./components/Services/Services'))
const Gallarys=lazy(()=>import('./components/Gallary/Gallary'))
// const About=lazy(()=>import('./components/About/About'))


function App() {
  
  return (
    <BrowserRouter>
{/*   
      <Demo/> */}
    {/* <Home/> */}
    <Suspense fallback={<div className='w-[100vw] flex h-[100vh] items-center justify-center text-[2rem] font-bold '><FaSpinner  className='text-[3rem] animate-spin'/></div>}>

    <Navbars />
    <NewHome/>
    {/* <Fnavbars/> */}
    <Routes>

    <Route path='/' element={<Cover/>}/>
    <Route path='/why' element={<Client/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/services' element={<Services/>}/>
    <Route path='/gallary' element={<Gallarys/>}/>
    <Route path='/blog' element={<Blog/>}/>
    <Route path='/blog/:id' element={<BlogDetails/>}/>

   {/* <Route path='/admin' element={<Demos/>}/> */}
    <Route path='/panel' element={<Admin/>}/> 
    </Routes>
    </Suspense>
    {/* <Fnavbars/> */}

    {/* <div className="max-sm:overflow-x-hidden flex-wrap w-[100%] ">
    <Cover/>
    <Why/>
    <How/>
    // <Footer/> */}
    {/* <div>
     <About/>
    </div>
    <Services/>
    <Specialization/>
    <Client/>
    <Gallary/>
    <Footer/> */}
 {/* <Main/> */}
    {/* </div> */}
    </BrowserRouter>
  );
}

export default App;
