import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {data} from '../Constant/blogs'
export default function BlogDetails() {
    const [blog,setBlog]=useState([])
   const {id}= useParams()
   useEffect(()=>{
    const blogData= data.filter((item)=>item.id=== parseInt(id))
    setBlog(blogData)
   },[id])
   console.log(blog)
  return (
    <section className='w-[100vw] flex justify-between overflow-hidden items-center'>
      <div className='max-w-[1350px] overflow-hidden flex w-[100%] px-[5%] py-[3rem] '>
        {blog && blog.map((data,index)=><div key={data.id} className='w-[100%] flex flex-col max-lg:gap-5 lg:gap-10'>
            <h1 className='lg:text-4xl md:text-2xl max-md:text-lg font-bold text-[#daa84c] text-center capitalize tracking-wider'>
            {data?.name}
            </h1>
            <div className='w-[100%] lg:h-[30rem] rounded-md overflow-hidden'>
                <img src={data?.image} className='w-[100%]' alt="blog image" />

            </div>
           
            <div className='text-right flex w-[100%] gap-10 items-end justify-end'>
            <h1 className="text-lg text-gray-500">By {data.author}</h1>

                <h1 className="text-lg text-gray-500"> {data.views}</h1>
                <h1 className="text-lg text-gray-500">{data.read}</h1>
            </div>
            <div className='flex flex-col gap-3'>
           
           {data.p.map((con,index)=> 
           <div key={con.pid} className='flex flex-col gap-0'>
               {con.head && <h1 className='font-semibold tracting-wider text-lg'>
                   {con.head}
                </h1>}
               {con.content && <p className='tracking-wider text-justify'>
                    {con.content}
                </p>}
            </div>)}
     
            </div>
        </div>)}

      </div>
    </section>
  )
}
